import {Avatar, Message} from "@chatscope/chat-ui-kit-react";
import React from "react";
import {useTranslation} from "react-i18next";
import {getAvatar, getMsgDirection, getTitle, resolveMsgType} from "../ChatHelper";

const SimpleMessage = ({m, playerNames, puzzle}) => {
    const { t: transPuzzle } = useTranslation('puzzle');
    return (
        <Message  model={{
            type: resolveMsgType(m),
            message: getTitle(m.title, transPuzzle, playerNames),
            sender: m.sender,
            direction: getMsgDirection(m.sender)
        }}>
            {m?.sender === 'game' && <Avatar src={getAvatar(puzzle)} name={m.sender} /> }
        </Message>
    )
}

export default SimpleMessage
