import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import TaggerCarousel from "./TaggerCarousel";
import {styled} from "@mui/material";
import AnswerDialogHeader from "./AnswerDialogHeader";
import AnswerDialogPaper from "./AnswerDialogPaper";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogSx = styled(Dialog)`
  .MuiDialog-container .MuiPaper-root {
    border-radius: 0;
    background-color: #e9e4de !important;
  }
`

export default function TagImagesDialog({tagImages, options, closeDialog, onSubmit}) {

    const handleClose = () => {
        closeDialog();
    };

    return (
        <DialogSx fullScreen open={tagImages !== null} onClose={handleClose} TransitionComponent={Transition}>
            <AnswerDialogHeader onBack={handleClose}/>
            <AnswerDialogPaper>
                <TaggerCarousel
                    images={tagImages?.action?.media}
                    options={options}
                    onSubmit={onSubmit}
                    closeDialog={closeDialog}
                />
            </AnswerDialogPaper>
        </DialogSx>
    );
}
