import { atom } from "recoil";

const timerStartedAtState = atom({
    key: "timerStartedAtState",
    default: null
});

const timerFinishedAtState = atom({
    key: "timerFinishedAtState",
    default: null
});

const timerConfigState = atom({
    key: "timerConfigState",
    default: null
});

export {
    timerStartedAtState,
    timerConfigState,
    timerFinishedAtState,
};
