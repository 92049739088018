
export const resolveErrorMessage = (error, unexpected) => {
  if (!error) {
    return null;
  }
  if (typeof error === 'string') {
    return error;
  }
  if (error.reason) {
    return error.reason;
  }
  if (error?.response?.data?.message) {
    return error?.response?.data?.message;
  }
  if (error?.message) {
    return error?.message;
  }
  if (error?.response?.data?.error) {
    return error?.response?.data?.error;
  }
  if (error?.error) {
    return error?.error;
  }

  return unexpected;
};
