import * as React from 'react';
import {
    Box, Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, InputLabel, MenuItem, Select
} from "@mui/material";
import {useTranslation} from "react-i18next";
import gameRoomApi from "../api/gameRoomApi";
import {useParams} from "react-router-dom";


export default function LangModal({ open, callback }) {

    const { t, i18n } = useTranslation();
    const { roomId } = useParams();

    const handleLanguageChange = (event) => {
        i18n.changeLanguage(event.target.value);
    };

    const onSubmit = () => {
        gameRoomApi.setLang(roomId, i18n.language).then(() => {
            callback();
        }).catch(console.error);
    }

    return (
        <Dialog
            open={open}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title" sx={{ color: 'white' }}>
                {t('selectLanguage')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ color: 'white' }}>
                    <Box mt={2}>
                        <FormControl fullWidth>
                            <InputLabel id="language-label">{t('language')}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="language"
                                value={i18n.language}
                                label={t('language')}
                                onChange={handleLanguageChange}
                            >
                                {['en', 'nl'].map(k => <MenuItem key={k} value={k}>
                                    {k === 'en' && t('languageEn')}
                                    {k === 'nl' && t('languageNl')}
                                </MenuItem>)}
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContentText>
                <DialogActions>
                    {<Button fullWidth size="large" variant="contained" color="success" onClick={onSubmit}>
                        {t('go')}
                    </Button>}
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}
