import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useTranslation} from "react-i18next";

const AnswerDialogHeader = ({ onBack,  fullWidth = false }) => {
    const { t } = useTranslation();
    const widthStyle = fullWidth ? { width: '100%' } : {};
    return (
        <AppBar sx={{position: 'relative', boxShadow: 'none', borderBottom: '1px solid #D2D2D2FF', ...widthStyle}}>
            <Toolbar sx={{background: '#F6F6F6', paddingX: 3}}>
                <IconButton edge="start" onClick={onBack} sx={{backgroundColor: '#E8E8E8'}}>
                    <ArrowBackIcon sx={{color: '#979797'}}/>
                </IconButton>
                <Typography sx={{ml: 2, flex: 1, color: '#979797', fontSize: 18}} component="div">
                    {t('backToChat')}
                </Typography>
            </Toolbar>
        </AppBar>
    )
}

export default AnswerDialogHeader;
