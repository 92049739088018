import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {alpha, Button} from "@mui/material";
import { useNavigate } from 'react-router-dom'
import {useParams} from "react-router-dom";
import InviteLinkBox from "./InviteLinkBox";
import {useEffect, useState} from "react";
import Divider from "@mui/material/Divider";
import gameApi from "../api/gameApi";
import {resolveErrorMessage} from "../utils/errors";
import {useTranslation} from "react-i18next";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    boxShadow: 24,
    p: 4,
    backgroundColor: "#1B2436",
    color: 'white',
    borderRadius: '20px',
};

export default function ProblemModal({ open, toggle }) {

    const [step, setStep] = useState(1);
    const {gameId, groupId} = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation('problemModal')

    useEffect(() => {
        if (!open) {
            setStep(1);
        }
    }, [open]);

    const continueClick = () => {
        if (step === 3) {
            return;
        }
        setStep(prevOpen => prevOpen + 1)
    }

    const getButton = (onClick, title) => (
        <Button size="large" variant="contained" sx={{width: '100%', marginTop: 4}} onClick={onClick}>{title}</Button>
    );

    const onPuzzleResetClick = () => {
        gameApi.resetPuzzle(gameId, groupId)
            .then(() => {
                continueClick();
            }).catch(({ response }) => {
                navigate('/error', { state: { title: resolveErrorMessage(response) }})
            })
    }

    const onReloadPage = () => {
        window.location.reload();
    }

    const getFirstStep = () => (
        <>
            <Typography id="modal-problem-body-1" variant="body6" sx={{color: alpha('#fff', 0.6)}}>
                {t('sorry')}
            </Typography>
            <Divider />
            <Typography id="modal-problem-body-1-1" variant="body6" sx={{color: alpha('#fff', 0.6), paddingTop: 2}}>
                {t('clickContinue')} <br /><br /><b>{t('areYouSure')}</b>
            </Typography>
            {getButton(onPuzzleResetClick, t('reset'))}
        </>
    )

    const getSecondStep = () => (
        <>
            <Typography id="modal-problem-body-2" variant="body6" sx={{color: alpha('#fff', 0.6)}}>
                {t('guestNeedRejoin')}
            </Typography>
            <InviteLinkBox />
            {getButton(continueClick, t('inviteContinue'))}
        </>
    )

    const getThirdStep = () => (
        <>
            <Typography id="modal-problem-body-2" variant="body6" sx={{color: alpha('#fff', 0.6)}}>
                {t('doneOrSupport')}
            </Typography>
            {getButton(onReloadPage, t('reloadPage'))}
        </>
    )

    return (
            <Modal
                open={open}
                onClose={toggle}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-problem-title" variant="h6" component="h2">
                        {t('problem')}
                    </Typography>
                    {step === 1 && getFirstStep()}
                    {step === 2 && getSecondStep()}
                    {step === 3 && getThirdStep()}
                </Box>
            </Modal>
    );
}
