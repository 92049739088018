// TaggerCarousel.js
import React, { useState } from "react";
import {
    Button,
    Checkbox,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    styled,
} from "@mui/material";
import Box from "@mui/material/Box";
import Slider from "react-slick";
import PaperSegment from "./PaperSegment";
import AnswerSubmitButton from "./AnswerSubmitButton";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { useTranslation } from "react-i18next";

const ButtonSx = styled(Button)`
  background-color: #88CD96 !important;
  box-shadow: none;
  height: 46px;
`;

const TaggerCarousel = ({ images, options, onSubmit, closeDialog }) => {
    const { t } = useTranslation("taggerCarousel");
    const { t : transPuzzle } = useTranslation("puzzle");
    const [openSelect, setOpenSelect] = useState(null);
    const [answerMap, setAnswerMap] = useState(new Map());
    const [activeSlide, setActiveSlide] = useState(0);
    const submitActive = answerMap?.size === images?.length;

    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: false,
        beforeChange: (current, next) => setActiveSlide(next),
    };

    const getValue = (index) => {
        const val = answerMap.get(index);
        if (val === undefined) {
            return [];
        }
        return val;
    };

    const onSubmitClick = () => {
        closeDialog();
        onSubmit(answerMap);
    };

    const onChange = (e, index) => {
        const { value } = e.target;
        const newMap = new Map(answerMap);
        newMap.set(index, typeof value === 'string' ? value.split(',') : value);
        setAnswerMap(newMap);
    };

    const closeSelect = () => {
        setOpenSelect(null);
    };

    return (
        <>
            <Stack m={1} sx={{ height: "100%", justifyContent: "space-between" }}>
                <Slider {...settings} m={1}>
                    {images?.map((src) => {
                        return (
                            <Box display="flex" key={src} height='100%' flexDirection="column" justifyContent='center'
                                 alignItems='center' padding={0}>
                                <Box
                                    component="img"
                                    sx={{
                                        height: '100%',
                                        width: '100%',
                                        margin: '0 auto',
                                        objectFit: 'contain',
                                        maxHeight: {xs: 333, md: 267},
                                        maxWidth: {xs: 450, md: 350},
                                        padding: 1
                                    }}
                                    src={src}
                                    alt="tag image"
                                />
                            </Box>
                        )
                    })}
                </Slider>
                <PaperSegment>
                    <Select
                        labelId={`index-select-label-${activeSlide}`}
                        value={getValue(activeSlide)}
                        sx={{
                            width: '100%',
                            marginTop: 4,
                            backgroundColor: 'transparent !important',
                            boxShadow: 'none !important',
                            svg: {
                                fill: 'black !important'
                            }
                        }}
                        MenuProps={{
                            sx: {
                                '.MuiPopover-paper': {
                                    backgroundColor: 'white !important',
                                    color: 'black !important',
                                    borderRadius: '8px'
                                },
                            }
                        }}
                        input={
                            <OutlinedInput label="Tag" />
                        }
                        inputProps={{ sx: { color: 'black !important'} }}
                        renderValue={(selected) => selected.join(', ')}
                        onChange={(e) => onChange(e, activeSlide)}
                        multiple
                        open={openSelect === activeSlide}
                        onOpen={() => setOpenSelect(activeSlide)}
                        onClose={() => closeSelect()}
                    >
                        {options?.map(o => [
                                <MenuItem key={o} value={o} sx={{ '&.Mui-selected': { background: '#DCF8C7' }}}>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlankRoundedIcon sx={{color: '#DCF8C7'}}/>}
                                        checkedIcon={<CheckBoxOutlinedIcon sx={{color: '#88CD96'}}/>}
                                        checked={getValue(activeSlide).includes(o)}
                                    />
                                    <ListItemText primary={transPuzzle(o)}/>
                                </MenuItem>
                            ]
                        )}
                        <Box sx={{marginLeft: 1, marginRight: 1}}>
                            <ButtonSx
                                fullWidth
                                variant="contained"
                                onClick={() => closeSelect()}
                            >
                                {t("confirmButton")}
                            </ButtonSx>
                        </Box>
                    </Select>
                    <Box mt={2}>
                        <AnswerSubmitButton onClick={onSubmitClick} disabled={!submitActive}>
                            {submitActive ? t("submitButton") : t("tagAllImagesButton")}
                        </AnswerSubmitButton>
                    </Box>
                </PaperSegment>
            </Stack>
        </>
    );
};

export default TaggerCarousel;
