import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import gameApi from "../api/gameApi";
import InviteModal from "../components/InviteModal";
import * as React from "react";
import Navbar from "../components/Navbar";
import {Stack} from "@mui/material";
import { useNavigate } from 'react-router-dom'
import Game from "../game/Game";
import HintItems from "../components/HintItems";
import useGuest from "../hooks/useGuest";
import Chatbar from "../components/Chatbar";
import ProblemModal from "../components/ProblemModal";
import {isGuestFromStore, isGuestSessionExists} from "../util/guestStorage";
import {useTranslation} from "react-i18next";
import InstructionsModal from "../components/InstructionsModal";

export default function GamePage() {
    const { gameId, groupId } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [inviteOpen, setInviteOpen] = useState(false);
    const [problemOpen, setProblemOpen] = useState(false);
    const [instructionsOpen, setInstructionsOpen] = useState(false);
    const [currentDifficulty, setCurrentDifficulty] = useState(null);
    const { isGuest } = useGuest();
    const [playerNames, setPlayerNames] = useState(null);
    const {i18n } = useTranslation();
    const {t:tPuzzle } = useTranslation('puzzle');

    const toggleInviteModal = () => {
        setInviteOpen(prevOpen => !prevOpen)
    }
    const toggleProblemModal = () => {
        setProblemOpen(prevOpen => !prevOpen)
    }
    const toggleInstructionModal = () => {
        setInstructionsOpen(prevOpen => !prevOpen)
    }

    useEffect(() => {
        window.sessionStorage.setItem("gameId", gameId);
        let isGuestFromSession = isGuest;
        if (isGuestSessionExists()) {
            isGuestFromSession = isGuestFromStore();
        }
        gameApi.getGame(gameId, groupId, isGuestFromSession)
            .then((data) => {
                if (data?.lang) {
                    i18n.changeLanguage(data?.lang);
                }
            }).catch(({ response }) => {
                navigate('/error', { state: { title: response?.data?.message }})
            })
    }, [gameId, groupId, isGuest, navigate, i18n]);

    useEffect(() => {
        const handlePopstate = (event) => {
            if (event && event.state) {
                // Reload the page
                window.location.reload();
            }
        };

        // Add the popstate event listener
        window.addEventListener('popstate', handlePopstate);

        return () => {
            // Remove the event listener when the component unmounts
            window.removeEventListener('popstate', handlePopstate);
        };
    }, []);

    window.addEventListener('popstate', function(event) {
        if (event.state && event.state.reload === true) {
            // Reload the page
            window.location.reload();
        }
    });

    // To force a page reload when the user navigates back
    window.history.pushState({ reload: true }, '');

    const getHints = () => {
        if (isGuest) {
            return data?.puzzle.guestHints;
        }
        return data?.puzzle.playerHints;
    }


    const hintItems = () => (
        <HintItems hints={getHints()} />
    )

    const getName = (data) => {
        if (data?.gameName !== undefined && data?.puzzle?.name !== undefined) {
            return tPuzzle(data?.gameName) + ': ' + tPuzzle(data?.puzzle?.name);
        }
        return '';
    }

    const getTimmerMessages = (data) => {
        if (isGuest) {
            return data?.puzzle.guestTimerMessages;
        }
        return data?.puzzle.playerTimerMessages;
    }

    const onSendTimerMessage = (message) => {
        const req = {message: message};
        console.log("sending message: ", message)
        gameApi.sendTimerMessage(gameId, groupId, req, isGuest)
            .then(response => {
                setData(response?.data);
            })
    }

    return (
            <Stack sx={{ height: '100%'}}>
            <Navbar name={getName(data)}
                    onInvite={toggleInviteModal}
                    timerMessages={getTimmerMessages(data)}
                    currentDifficulty={currentDifficulty}
                    hintItems={hintItems()}
                    onResetPuzzle={toggleProblemModal}
                    toggleInstructionModal={toggleInstructionModal}
                    onSendTimerMessage={onSendTimerMessage}
            />
            <Chatbar data={data} playerNames={playerNames} />
            <InviteModal open={inviteOpen} toggle={toggleInviteModal} />
            <ProblemModal open={problemOpen} toggle={toggleProblemModal} />
            <InstructionsModal open={instructionsOpen} toggle={toggleInstructionModal} />
            <Game
                data={data}
                setData={setData}
                setCurrentDifficulty={setCurrentDifficulty}
                setPlayerNames={setPlayerNames}
                playerNames={playerNames}
                gameName={tPuzzle(data?.gameName)}
                puzzleName={tPuzzle(data?.puzzle?.name)}
            />
            </Stack>
    );
}
