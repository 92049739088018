import Typography from "@mui/material/Typography";
import * as React from "react";
import {CopyField} from "@eisberg-labs/mui-copy-field";
import {getInviteLink} from "../utils/shared";
import {Stack} from "@mui/material";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

const InviteLinkBox = () => {
    const { gameId, groupId } = useParams();
    const { t } = useTranslation();
    return (
        <Stack spacing={2}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t('copyLink')}
            </Typography>
            <CopyField
                sx={{marginTop: 1}}
                label={t('copyLinkLabel')}
                value={getInviteLink(gameId, groupId)}
            />
        </Stack>
    )
}

export default InviteLinkBox;
