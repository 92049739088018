import {Avatar, Message, MessageGroup} from "@chatscope/chat-ui-kit-react";
import {getAvatar} from "../ChatHelper";
import React from "react";
import {useTranslation} from "react-i18next";
import {Button} from "@mui/material";

const OptionOrderTagMessage = ({m, puzzle, disabledTag, onClick, titleMsg, images}) => {
    const { t } = useTranslation()

    return (
        <>
            {images}
            <MessageGroup direction="incoming" sender={m.sender} avatarPosition="cl">
                {m?.sender === 'game' && <Avatar src={getAvatar(puzzle)} name={m.sender}/>}
                <MessageGroup.Messages>
                    {titleMsg}
                    <Message
                        model={{
                            type: 'custom',
                            sender: m.sender
                        }}>
                        <Message.CustomContent>
                            <Button onClick={() => onClick(m)} disabled={disabledTag}>{t('clickToTagAnswerInOrder')}</Button>
                        </Message.CustomContent>
                    </Message>
                </MessageGroup.Messages>
            </MessageGroup>
        </>
    )
}

export default OptionOrderTagMessage
