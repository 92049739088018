import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import {Menu, MenuItem, Stack, useMediaQuery} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import {useTranslation} from "react-i18next";

function PuzzleListMenu({toggleInstructionModal}) {

    const handleOnPageClick = (url, toggle) => {
        if ('#' === url) {
            toggle();
        } else {
            window.open(url, '_blank', 'noreferrer');
        }
    }
    const isMobile = useMediaQuery('(max-width:768px)');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t } = useTranslation();
    const { t:tNav } = useTranslation('');
    const { t: resultsNav } = useTranslation('resultsNavigation');

    const pages = [
        { url: "#", label: tNav('instructions'), toggle: () => toggleInstructionModal() },
        { url: resultsNav('faqUrl'), label: t('faq') },
        { url: resultsNav('shopUrl'), label: t('shop') }
    ];

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderLink = (page) => [
        <Button
            key={page.label}
            onClick={() => handleOnPageClick(page.url, page.toggle)}
            sx={{my: 2, color: 'white', display: 'block'}}
        >
            {page.label}
        </Button>
    ];

    const renderMenuItem = (page) => [
        <MenuItem
            key={page.label}
            onClick={() => {
                handleOnPageClick(page.url, page.toggle);
                handleClose();
            }}
        >
            {page.label}
        </MenuItem>
        ];

    return (
        <AppBar position="static" sx={{ background: '#111827', boxShadow: 'none'}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <img
                        style={{maxWidth: '130px', filter: 'invert(1)'}}
                        alt="logo"
                        src="https://couchclues.com/wp-content/uploads/2023/07/CC_full_keyhole_black.png"
                    />
                    <Stack direction="row">
                        {isMobile
                            ? (
                                <>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        sx={{mr: 2, background: 'rgba(25, 34, 53, 1)', borderColor: 'rgba(255, 255, 255, 0.06)', width: '42px', height: '42px'}}
                                        onClick={handleMenuOpen}
                                    >
                                        <MenuIcon sx={{ fontSize: 20 }}/>
                                    </IconButton>
                                    <Menu
                                        anchorEl={anchorEl}
                                        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                                        keepMounted
                                        transformOrigin={{vertical: 'top', horizontal: 'right'}}
                                        open={!!anchorEl}
                                        onClose={handleClose}
                                    >
                                        {pages.map(renderMenuItem)}
                                    </Menu>
                                </>
                            )
                            : pages.map(renderLink)
                        }
                    </Stack>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default PuzzleListMenu;
