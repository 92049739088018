import {createBrowserRouter} from "react-router-dom";
import ErrorPage from "./pages/error-page";
import GamePage from "./pages/GamePage";
import PuzzleListPage from "./pages/PuzzleListPage";
import PaymentPage from "./pages/PaymentPage";

const router = createBrowserRouter([
    // { path: "/sandbox", element: <Sandbox />, errorElement: <ErrorPage /> },
    { path: "/payment", element: <PaymentPage />, errorElement: <ErrorPage /> },
    {
        path: "/:roomId",
        element: <PuzzleListPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/:gameId/:groupId",
        element: <GamePage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/:gameId/:groupId/guest",
        element: <GamePage />,
        errorElement: <ErrorPage />,
    },
    { path: "/error/*", element: <ErrorPage />, errorElement: <ErrorPage /> },
    { path: "/*", element: <ErrorPage />, errorElement: <ErrorPage /> },
]);

export default router;
