import * as React from 'react';
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from "react-i18next";
import {handleLineBreaks} from "../util/textFormat";

export default function HintModal({ hint, onClose }) {

    const { t } = useTranslation('puzzle');

    const getTitle = (value) => {
        if (value?.startsWith('translate')) {
            return handleLineBreaks(t(value));
        }
        return value;
    };

    return (
        <Dialog
            open={hint !== null}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title" sx={{ color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {getTitle(hint?.label)}
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={onClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ color: 'white' }}>
                    {getTitle(hint?.value)}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}
