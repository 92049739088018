import * as React from "react";
import {styled} from "@mui/material";
import Button from "@mui/material/Button";
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';

const ButtonSx = styled(Button)`
  background-color: #FF5757 !important;
  box-shadow: none;

  &:disabled {
    background-color: #DDDDDD !important;
  }
`;

const AnswerClearButton = ({ children, onClick, disabled }) => {
    return (
        <ButtonSx
            disabled={disabled}
            size="small"
            endIcon={<DisabledByDefaultIcon/>}
            variant="contained"
            onClick={onClick}

        >
            {children}
        </ButtonSx>
    )
}

export default AnswerClearButton;
