import * as React from 'react';
import {useState} from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import moment from "moment";
import SendIcon from '@mui/icons-material/Send';
import {alpha, Box, SwipeableDrawer} from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import TimerIcon from '@mui/icons-material/Timer';
import {useTranslation} from "react-i18next";

export default function PuzzleCard(
    {
        gameTitle,
        puzzleName,
        cover,
        expiresAt,
        playerCompletedIn,
        difficulty,
        playerHintsUsed,
        guestHintsUsed,
        playerSolutionsTried,
        guestSolutionsTried
    }
) {

    const [state, setState] = useState({
        bottom: false,
    });
    const { t } = useTranslation('puzzleCard')
    const { t: tPuzzle } = useTranslation('puzzle')

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({...state, [anchor]: open});
    };

    const list = (anchor) => (
        <Box
            sx={{width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250}}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List sx={{
                '.MuiListItemIcon-root': {
                    color: '#FF5757'
                },
                '.MuiListItemText-primary': {
                    color: 'white',
                    fontSize: 14,
                },
                '.MuiListItemText-secondary': {
                    color: '#FF5757',
                    fontSize: 16,
                    fontWeight: 'bold',
                    backgroundColor: alpha('#28354F', 0.3),
                    padding: '8px 12px',
                    borderRadius: 2,
                    marginTop: 1
                }
            }}
            >
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <FitnessCenterIcon/>
                        </ListItemIcon>
                        <ListItemText
                            primary={t('difficultyPlayed')}
                            secondary={tPuzzle(`translate.game.${difficulty}`)}
                        />
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <QuestionMarkIcon/>
                        </ListItemIcon>
                        <ListItemText
                            primary={t('hintsUsedByPlayer')}
                            secondary={playerHintsUsed}
                        />
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <QuestionMarkIcon/>
                        </ListItemIcon>
                        <ListItemText
                            primary={t('hintsUsedByGuest')}
                            secondary={guestHintsUsed}
                        />
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <SendIcon/>
                        </ListItemIcon>
                        <ListItemText
                            primary={t('solutionsTriedByPlayer')}
                            secondary={playerSolutionsTried}
                        />
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <SendIcon/>
                        </ListItemIcon>
                        <ListItemText
                            primary={t('solutionsTriedByGuest')}
                            secondary={guestSolutionsTried}
                        />
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <TimerIcon/>
                        </ListItemIcon>
                        <ListItemText
                            primary={`Puzzle completed in:`}
                            secondary={getHumanCompletedIn(playerCompletedIn)}
                        />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    const renderExpires = () => {
        if (expiresAt === null) {
            return;
        }
        const expiresIn = moment.utc(expiresAt).from(moment().local());
        return (
            <Typography sx={{color: 'white', fontSize: 12}}>
                {t('expires')} {expiresIn}
            </Typography>
        )
    }

    const renderButtons = () => {
        return (
            <CardActions sx={{display: 'flex', justifyContent: 'space-between'}}>
                {<Button fullWidth disabled={playerCompletedIn === null} size="medium" variant="outlined"
                         onClick={toggleDrawer('bottom', true)}>{t('seeStats')}</Button>}
            </CardActions>
        )
    }

    const getHumanCompletedIn = (completedIn) => {
        if (completedIn !== null) {
            return completedIn;
        }
        return '-';
    }

    return (
        <Card sx={{borderRadius: '14px', boxShadow: 'none', backgroundColor: 'transparent', color: 'white'}}>
            <CardMedia
                component="img"
                alt="puzzle cover"
                height="172"
                image={cover}
                sx={{borderRadius: '14px'}}
            />
            <CardContent>
                <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontWeight: 'bold'
                    }}
                >
                    {puzzleName}
                    {renderExpires()}
                </Typography>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography variant="body6" sx={{color: alpha('#fff', 0.6)}}>
                        {gameTitle}
                    </Typography>
                </Box>
            </CardContent>
            {renderButtons()}
            <React.Fragment>
                <SwipeableDrawer
                    anchor={'bottom'}
                    open={state['bottom']}
                    onClose={toggleDrawer('bottom', false)}
                    onOpen={toggleDrawer('bottom', true)}
                >
                    {list('bottom')}
                </SwipeableDrawer>
            </React.Fragment>
        </Card>
    )
}
