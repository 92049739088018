import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import * as React from "react";
import HintModal from "./HintModal";
import { useEffect, useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from "react-i18next";
import useGuest from "../hooks/useGuest";
import { useParams } from "react-router-dom";
import gameApi from "../api/gameApi";
import { useRecoilState } from "recoil";
import { timerStartedAtState } from "../recoil_state";

export default function HintItems({ hints }) {
    const { gameId, groupId } = useParams();
    const { isGuest } = useGuest();
    const [hint, setHint] = useState(null);
    const [used, setUsed] = useState([]);
    const [disabledHints, setDisabledHints] = useState({});
    const { t } = useTranslation('puzzle');
    const [timerStartedAt] = useRecoilState(timerStartedAtState);

    useEffect(() => {
        const checkHints = async () => {
            const newDisabledHints = {};
            for (const hint of hints) {
                newDisabledHints[hint.type] = await isHintDisabled(hint.disableAfter, hint.type);
            }
            setDisabledHints(newDisabledHints);
        };
        checkHints();
    }, [hints]);

    const onHintClick = (hint, type) => {
        setHint(hint);
        const arr = [...used, type];
        setUsed(arr);
        gameApi.getHint(gameId, groupId, isGuest, type)
            .then(h => {
                // Handle the response if needed
            });
    };

    const updateUsedWithAllPlayerOpenedHintByValue = (hintType) => {
        return gameApi.didAnyPlayerOpenedHint(gameId, groupId, isGuest, hintType)
            .then(hintUsageView => {
                return hintUsageView.used;
            });
    };

    const translate = (title) => {
        if (title?.startsWith('translate')) {
            return t(title);
        }
        return title;
    };

    const isHintDisabled = async (disableAfter, hintType) => {
        if (!disableAfter) {
            return false;
        }
        if (!timerStartedAt) {
            return false;
        }

        const currentTime = new Date();
        const startTime = new Date(timerStartedAt);
        const elapsedTime = (currentTime - startTime) / 1000; // Convert milliseconds to seconds
        const timeDisabled = elapsedTime > disableAfter; // true if we should hide hint on that time
        const hintUsedAlready = await updateUsedWithAllPlayerOpenedHintByValue(hintType);
        if (timeDisabled) {
            if (hintUsedAlready) {
                return false;
            }
            return true; // time passed and it was not used
        }
        return false;
    };

    const renderSolution = () => {
        return hints?.filter(h => h.type === 'SOLUTION').map(h =>
            <ListItem key={h?.label + "-" + h?.value}>
                <ListItemButton
                    disabled={disabledHints[h?.type] || false}
                    onClick={() => onHintClick(h, h?.type)}
                >
                    <ListItemText primary={translate(h?.label)} />
                </ListItemButton>
            </ListItem>
        );
    };

    const hintsList = () => (
        <>
            { hints?.filter(h => h.type !== 'SOLUTION').map(h =>
                <ListItem key={h?.label + "-" + h?.value}>
                    <ListItemButton
                        disabled={disabledHints[h?.type] || false}
                        onClick={() => onHintClick(h, h?.type)}
                    >
                        <ListItemText primary={translate(h?.label)} />
                    </ListItemButton>
                </ListItem>
            )}
            {renderSolution()}
        </>
    );

    return (
        <>
            <ListItem sx={{ paddingX: 0 }}>
                <ListItemButton>
                    <ListItemText primary={t('translate.game.hint.hints.label')} />
                    <KeyboardArrowDownIcon />
                </ListItemButton>
            </ListItem>
            <HintModal hint={hint} onClose={() => setHint(null)} />
            {hintsList()}
        </>
    );
}
