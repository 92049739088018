import {Avatar, Message, MessageGroup} from "@chatscope/chat-ui-kit-react";
import {getAvatar, getTitle} from "../ChatHelper";
import React from "react";
import {useTranslation} from "react-i18next";
import {Button} from "@mui/material";

const ImageTagMessage = ({m, puzzle, disabledTag, onClick, playerNames}) => {
    const { t: transPuzzle } = useTranslation('puzzle');
    const { t } = useTranslation()

    return (
        <MessageGroup direction="incoming" sender={m.sender} avatarPosition="cl">
            {m?.sender === 'game' && <Avatar src={getAvatar(puzzle)} name={m.sender}/>}
            <MessageGroup.Messages>
                <Message model={{message: getTitle(m.title, transPuzzle, playerNames)}}/>
                <Message
                    model={{
                        type: 'custom',
                        sender: m.sender
                    }}>
                    <Message.CustomContent>
                        <Button onClick={() => onClick(m)} disabled={disabledTag}>{t('clickToTag')}</Button>
                    </Message.CustomContent>
                </Message>
            </MessageGroup.Messages>
        </MessageGroup>
    )
}

export default ImageTagMessage
