import { coreClient } from './config';

const gameApi = {
    getGame: (gameId, uuid, isGuest) => coreClient
            .get(`/game/${gameId}/${uuid}?isGuest=${isGuest}`)
            .then(({data}) => data),
    getHint: (gameId, uuid, isGuest, type) => coreClient
            .get(`/game/${gameId}/${uuid}/hint?isGuest=${isGuest}&type=${type}`)
            .then(({data}) => data),
    didAnyPlayerOpenedHint: (gameId, uuid, isGuest, hintType) => coreClient
            .get(`/game/${gameId}/${uuid}/hint/usage?isGuest=${isGuest}&type=${hintType}`)
            .then(({data}) => data),
    sendMessage: (gameId, uuid, body, isGuest) => coreClient
            .post(`/game/${gameId}/${uuid}?isGuest=${isGuest}`, body)
            .then((response) => response),
    sendTimerMessage: (gameId, uuid, body, isGuest) => coreClient
            .post(`/game/${gameId}/${uuid}/timer?isGuest=${isGuest}`, body)
            .then((response) => response),
    setPlayerReady: (gameId, uuid, isGuest, difficulty, guestName) => coreClient
        .post(`/game/${gameId}/${uuid}/player-ready?isGuest=${isGuest}&difficulty=${difficulty}`, {guestName})
        .then(({data}) => data),
    getPlayerWaiting: (gameId, uuid, isGuest) => coreClient
        .get(`/game/${gameId}/${uuid}/player-waiting?isGuest=${isGuest}`)
        .then(({data}) => data),
    isAnotherPlayerFinished: (gameId, uuid, isGuest) => coreClient
        .get(`/game/${gameId}/${uuid}/is-main-player-finished?isGuest=${isGuest}`)
        .then(({data}) => data),
    canMainPlayerUnlockNextPuzzle: (gameId, uuid, isGuest) => coreClient
        .get(`/game/${gameId}/${uuid}/can-main-player-unlock-next-puzzle?isGuest=${isGuest}`)
        .then(({data}) => data),
    resetPuzzle: (gameId, uuid) => coreClient
        .post(`/game/${gameId}/${uuid}/reset-puzzle`),
};
export default gameApi;
