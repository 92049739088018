import React from 'react';
import {alpha, Box, Button, Typography} from '@mui/material';
import {useTranslation} from "react-i18next";

export default function PaymentPage() {
    const navigateOutApp = () => window.open("https://couchclues.com/chapters/a-toxic-case/full/", "_blank", "noreferrer");
    const { t } = useTranslation();
    const navigateToHome = () => {
        if (window.sessionStorage.getItem("gameId")) {
            window.location.href = window.sessionStorage.getItem("gameId");
            return;
        }
        window.open("https://couchclues.com/", "_blank", "noreferrer");
    }

    const getTitle = () => {
        return t('buyAnother');
    }
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                flexDirection: 'column'
            }}
            spacing={2}
        >
            <Typography variant="h3" sx={{ color: 'white' }}>
                {t('youDontHave')}
            </Typography>
            <Typography variant="h6" sx={{ color: alpha('#fff', 0.6) }} m={1}>
                {getTitle()}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'row',
                    width: '100%'
                }}
            >
                <Button variant="contained" onClick={navigateOutApp}>{t('getPuzzle')}</Button>
                <Button variant="outlined" onClick={navigateToHome}>{t('errorGoHome')}</Button>
            </Box>
        </Box>
    );
}
