import { coreClient } from './config';

const gameRoomApi = {
    getRooms: (roomId, isGuest) => coreClient
            .get(`/game/rooms/${roomId}?isGuest=${isGuest}`)
            .then(({data}) => data),
    setLang: (roomId, lang) => coreClient
            .post(`/game/rooms/${roomId}/lang`, { lang }),
};
export default gameRoomApi;
