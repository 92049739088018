import React, {useEffect, useState} from "react";

import {
    MessageList,
    Message,
    MessageInput,
} from '@chatscope/chat-ui-kit-react';
import { v4 as uuidv4 } from 'uuid';
import Lightbox, {useController} from 'yet-another-react-lightbox';
import {Button, Stack} from "@mui/material";
import useGuest from "../hooks/useGuest";
import background from "../media/bg/chat.png";
import {Zoom} from "yet-another-react-lightbox/plugins";
import AnswerDialogHeader from "../components/AnswerDialogHeader";
import {useTranslation} from "react-i18next";
import {getMessages, getTitle} from "./ChatHelper";
import SimpleMessage from "./messages/SimpleMessage";
import ImageMessage from "./messages/ImageMessage";
import ImageTagMessage from "./messages/ImageTagMessage";
import OptionTagMessage from "./messages/OptionTagMessage";
import OptionOrderTagMessage from "./messages/OptionOrderTagMessage";
import {useRecoilState} from "recoil";
import {timerFinishedAtState} from "../recoil_state";

const ChatRoot = ({ data, onSend, onTagImages, onTagOptions, showWaitPartner, onTryToProceed, onOrderTagOptions, onResultsClick, playerNames }) => {
    const { puzzle } = data;
    const { isGuest } = useGuest();
    const { t } = useTranslation()
    const { t: transPuzzle } = useTranslation('puzzle');

    const [tagType, setTagType] = useState(false);
    const [lightBoxOpen, setLightBoxOpen] = useState(false);
    const [openGalleryStartingAt, setOpenGalleryStartingAt] = useState();
    const [images, setImages] = useState([]);
    const disabledTag = showWaitPartner || onResultsClick !== null
    const [, setTimerFinishedAt] = useRecoilState(timerFinishedAtState);

    useEffect(() => {
        if (openGalleryStartingAt !== undefined) {
            setLightBoxOpen(true);
        } else {
            setLightBoxOpen(false);
        }
    }, [openGalleryStartingAt]);

    useEffect(() => {
        let tagType = null;
        getMessages(isGuest, puzzle).forEach((m) => {
                const action = m.action;
                if (action?.mediaType === 'imageTag') {
                    tagType = action?.mediaType;
                }
                if (action?.mediaType === 'optionTag') {
                    tagType = action?.mediaType;
                }
                if (action?.mediaType === 'optionOrderTag') {
                    tagType = action?.mediaType;
                }
            })
        setTagType(tagType);
    }, [puzzle]); // eslint-disable-line

    useEffect(() => {
        const images = getMessages(isGuest, puzzle).flatMap(m => {
            return m?.action?.media?.map(source => {
                return ({
                    src: transPuzzle(source)
                });
            }) || [];
        });
        setImages(images);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [puzzle])

    const onOpenImage = (index) => {
        setOpenGalleryStartingAt(index);
    };

    const renderSimpleMessage = (m, k) => {
        if (m?.action?.type === 'continue') {
            console.log("setting timer finished at to now!")
            // backup timer stop
            setTimerFinishedAt(new Date());
        }

        return (
            <SimpleMessage
                key={k + m.title + uuidv4()}
                m={m}
                playerNames={playerNames}
                puzzle={puzzle}
            />
        )
    }

    const renderImageMessage = (m, k, renderTitle = true) => {
        return (
            <ImageMessage
                key={k + m.title + uuidv4()}
                onClick={onOpenImage}
                m={m}
                renderTitle={renderTitle}
                puzzle={puzzle}
                images={images}
                playerNames={playerNames}
            />
        )
    }

    const renderImageTagMessage = (m, k) => {
        return (
            <ImageTagMessage
                key={k + m.title + uuidv4()}
                onClick={onTagImages}
                m={m}
                disabledTag={disabledTag}
                playerNames={playerNames}
                puzzle={puzzle}
            />
        )
    }

    const renderOptionTagMessage = (m, k) => {
        return (
            <OptionTagMessage
                key={k + m.title + uuidv4()}
                onClick={onTagOptions}
                m={m}
                disabledTag={disabledTag}
                playerNames={playerNames}
                puzzle={puzzle}
            />
        )
    }

    const renderOptionOrderTagMessage = (m, k) => {
        let images = <></>;
        let titleMsg = <Message model={{message: getTitle(m.title, transPuzzle, playerNames)}}/>;
        if (parseInt(m?.action?.media?.length, 10) >= 1) {
            titleMsg = <></>; // no need to duplicate since we print it before images
            images = renderImageMessage(m, k)
        }

        return (
            <OptionOrderTagMessage
                key={k + m.title + uuidv4()}
                onClick={onOrderTagOptions}
                m={m}
                disabledTag={disabledTag}
                titleMsg={titleMsg}
                puzzle={puzzle}
                images={images}
            />
        )
    }

    const renderMsgImages = (m, k, renderTitle) => {
        if (parseInt(m?.action?.media?.length, 10) >= 1) {
            return renderImageMessage(m, k, renderTitle);
        }
    }

    const resolveMessage = (m, k) => {
        if (!m.time) {
            // if no time provided - ignoring the question
            return;
        }
        const action = m.action;
        if (action?.mediaType === 'image') {
            return renderImageMessage(m, k);
        }
        if (action?.mediaType === 'imageTag') {
            return [renderMsgImages(m, k, false), renderImageTagMessage(m, k)];
        }
        if (action?.mediaType === 'optionTag') {
            return [renderMsgImages(m, k, false), renderOptionTagMessage(m, k)];
        }
        if (action?.mediaType === 'optionOrderTag') {
            return [renderOptionOrderTagMessage(m, k)];
        }
        return [renderSimpleMessage(m, k), renderMsgImages(m, k, true)];
    }

    const getProceedText = () => {
        return <MessageInput activateAfterChange={false} autoFocus={false} sendDisabled={false} attachButton={false} placeholder={t('go')} value={t('go')} onSend={onTryToProceed} />;

    }
    const resolveUserActionInput = () => {
        if (showWaitPartner) {
            return getProceedText();
        }
        if (onResultsClick !== null) {
            return <Button size="large" variant="contained" color="success" onClick={onResultsClick} fullWidth>{t('results')}</Button>
        }
        if (tagType != null) {
            return <></>;
        }
        return <MessageInput activateAfterChange={false} autoFocus={false} attachButton={false} placeholder={t('typeToAnswer')} onSend={onSend} />;
    }

    const singleImageClass = images.length === 1 ? 'single-image' : '';

    const CustomToolbar = () => {
        const { close } = useController();

        const onClose = () => {
            setLightBoxOpen(false);
            setOpenGalleryStartingAt(undefined);
            close();
        }

        return (
            <AnswerDialogHeader onBack={onClose} fullWidth={true}  />
        );
    }

    return (
        <>
            <Lightbox
                index={openGalleryStartingAt}
                open={lightBoxOpen}
                close={() => setOpenGalleryStartingAt(undefined)}
                slides={images}
                plugins={[Zoom]}
                toolbar={{
                    buttons: [<CustomToolbar key="answer-dialog" />],
                }}
                className={singleImageClass}
                zoom={{
                    maxZoomPixelRatio: 3,
                }}
            />
            <MessageList>
                <Stack sx={{
                    padding: '0 1.2em 2em 0.8em',
                    background: `repeat url(${background})`,
                    backgroundPosition: 'center',
                    backgroundAttachment: 'fixed',
                }}>
                    {getMessages(isGuest, puzzle).map((m, k) => resolveMessage(m, k))}
                </Stack>
            </MessageList>
            { resolveUserActionInput() }
        </>
    );
};

export default ChatRoot
