import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InviteLinkBox from "./InviteLinkBox";
import {useTranslation} from "react-i18next";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    boxShadow: 24,
    p: 4,
    backgroundColor: "#1B2436",
    color: 'white',
    borderRadius: '20px',
};

export default function InviteModal({ open, toggle }) {
    const { t } = useTranslation();
    return (
            <Modal
                open={open}
                onClose={toggle}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                fullWidth
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t('inviteFriend')}
                    </Typography>
                    <InviteLinkBox />
                </Box>
            </Modal>
    );
}
