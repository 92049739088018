import React from "react";
import Slider from "react-slick";
import Box from "@mui/material/Box";
import {Stack} from "@mui/material";
import {useTranslation} from "react-i18next";

export default function TaggerCarousel({images}) {

    const { t } = useTranslation('puzzle');

     const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: false,
        swipe: false,
    };

    return (
            <Slider {...settings} >
                {images?.map((source, index) => (
                    <Box
                        key={source + index}
                        display="flex"
                        height="60vh"
                        maxHeight="480px"
                        flexDirection="column"
                        justifyContent='center'
                        alignItems='center'
                        padding={1}
                    >
                        <Stack
                            padding={"3px"}
                            sx={{ borderRadius: '9px'}}
                            height="100%"
                        >
                            <Box
                                component="img"
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                    margin: '0 auto',
                                    maxHeight: 'auto',
                                    borderRadius: '6px',
                                    objectFit: 'contain'
                                }}
                                src={t(source)}
                                alt="tag image"
                            />
                        </Stack>
                    </Box>
                ))}
            </Slider>
    );
}
