import {useLocation} from "react-router-dom";
import {setGuestStore} from "../util/guestStorage";

const useGuest = () => {
    const location = useLocation();
    const isGuest = location.pathname.endsWith("/guest");
    setGuestStore(isGuest);
    return { isGuest };
};
export default useGuest;
