import React from 'react';

export const handleLineBreaks = (text) => {
    // Split the text into lines
    const lines = text.split('<br>');

    // Return a component that renders each line, separated by <br />
    return (
        <>
            {lines.map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    {index < lines.length - 1 && <br />}
                </React.Fragment>
            ))}
        </>
    );
};
