// TagOptionsDialog.jsx
import * as React from 'react';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Box from "@mui/material/Box";
import Chip from '@mui/material/Chip';
import { Stack, styled } from "@mui/material";
import TaggerCarousel from "./TagOptionsCarousel";
import { createMultiple } from "../utils/jsx";
import PaperSegment from "./PaperSegment";
import AnswerSubmitButton from "./AnswerSubmitButton";
import AnswerDialogHeader from "./AnswerDialogHeader";
import AnswerDialogPaper from "./AnswerDialogPaper";
import { useTranslation } from "react-i18next";
import AnswerClearButton from "./AnswerClearButton";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogSx = styled(Dialog)`
  .MuiDialog-container .MuiPaper-root {
    border-radius: 0;
    background-color: #e9e4de !important;
  }
`

const ChipListSx = styled(Stack)`
  justify-content: flex-start;
  flex-direction: row;
  margin-top: 10px;
  flex-wrap: wrap;
  gap: 4px;
  row-gap: 10px;
`

const ChipSx = styled(Chip)`
  color: black;
  border-radius: 6px;
  font-size: 17px;
  height: 42px;
  background-color: #DCF8C7;

  svg {
    fill: #979797;
  }

  &:hover, &:focus {
    background-color: #DCF8C7; 
  }

  &.Mui-selected {
    background-color: #DCF8C7;  
    color: black; 
  }
`

const EmptyChipSx = styled(Stack)`
  border-radius: 6px;
  height: 40px;
  width: 100%;
  border: 2px dashed #DCF8C7;

  &:hover, &:focus {
    background-color: #DCF8C7;
  }

  &.Mui-selected {
    background-color: #DCF8C7;
    color: black;
  }
`

export default function TagOptionsDialog(
    {
        tagOptions,
        open,
        availableOptions,
        closeDialog,
        onSubmit,
        isOrdered = false
    }
) {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [options, setOptions] = useState(availableOptions);
    const { t } = useTranslation("tagOptionsDialog");
    const { t : transPuzzle } = useTranslation("puzzle");

    const handleClose = () => {
        closeDialog();
    };

    const onSubmitClick = () => {
        const translatedSubmit = selectedOptions.map(o => transPuzzle(o));
        closeDialog();
        onSubmit(translatedSubmit)
    }

    const handleClick = (selected) => () => {
        setSelectedOptions((chips) => [...chips, selected]);
        setOptions((chips) => chips.filter((chip) => chip !== selected));
    };

    const handleDelete = (selected) => () => {
        setOptions((chips) => [...chips, selected]);
        setSelectedOptions((chips) => chips.filter((chip) => chip !== selected));
    };

    const onClearClick = () => {
        setOptions(availableOptions)
        setSelectedOptions([])
    }

    const renderLabel = (label, index) => {
        if (!isOrdered) {
            return label;
        }
        const number = index + 1;
        return `${number}. ${transPuzzle(label)}`;
    }

    const renderGallery = () => {
        if (tagOptions?.action?.media?.length > 0) {
            return <TaggerCarousel images={tagOptions?.action?.media} />
        }
    }

    const renderOptionChip = (option) => {
        const translated = transPuzzle(option);
        return (
            <ChipSx
                label={translated}
                key={option}
                value={option}
                onClick={handleClick(option)}
            />
        );
    }

    const renderSelectedChip = (option, index) => {
        return [
            <ChipSx
                label={renderLabel(option, index)}
                key={option}
                value={option}
                onClick={handleDelete(option)}
                onDelete={handleDelete(option)}
            />
        ];
    }

    return (
        <DialogSx fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AnswerDialogHeader onBack={handleClose} />
            <AnswerDialogPaper>
                <Stack mb={5} mx={2}>
                    {renderGallery()}
                </Stack>

                <Stack px={2}>
                    <PaperSegment>
                        <Typography variant="h5" component="div">
                            {t('availableOptions', { count: options?.length })}
                        </Typography>
                        <ChipListSx>
                            {options?.sort().map(renderOptionChip)}
                        </ChipListSx>
                    </PaperSegment>

                    <PaperSegment mt={1}>
                        <Typography variant="h5" component="div">
                            {`${t('selected')} ${isOrdered ? t('inOrder') : ''} (${selectedOptions.length})`}
                        </Typography>
                        <ChipListSx>
                            {selectedOptions.length !== 0 &&<AnswerClearButton onClick={() => onClearClick()}>{t('clearBtn')}</AnswerClearButton> }
                            {selectedOptions.length === 0 && <EmptyChipSx/>}
                            {selectedOptions.map(renderSelectedChip)}
                        </ChipListSx>
                        <Box mt={2}>
                            <AnswerSubmitButton onClick={onSubmitClick} disabled={selectedOptions.length === 0}>
                                {t('submitButton')}
                            </AnswerSubmitButton>
                        </Box>
                    </PaperSegment>
                </Stack>
            </AnswerDialogPaper>
        </DialogSx>
    );
}
