import React, {useState, useEffect, forwardRef} from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import {Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useRecoilState} from "recoil";
import {timerConfigState, timerStartedAtState} from "../recoil_state";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function CountdownOverlay() {
    const {t: tPuzzle} = useTranslation('puzzle');
    const [countdown, setCountdown] = useState(0);
    const [timerStartedAt] = useRecoilState(timerStartedAtState);
    const [timerConfig, setTimerConfig] = useRecoilState(timerConfigState);

    const handleClose = () => {
        setCountdown(0);
    };

    // Effect to decrement countdown every second
    useEffect(() => {
        let interval;
        if (countdown > 0) {
            interval = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else {
            handleClose();
        }

        // Cleanup the interval on component unmount or countdown reaching zero
        return () => clearInterval(interval);
    }, [countdown]);

    useEffect(() => {
        if (timerStartedAt) {
            const futureStart = new Date(timerStartedAt);
            const now = new Date();

            if (futureStart > now) {
                if (timerConfig !== null) {
                    /**
                     * we will be listening to seconds left before start of the game.
                     * this is needed in case guest player joins later
                     */
                    const secondsUntilStart = Math.floor((futureStart.getTime() - now.getTime()) / 1000);
                    setCountdown(secondsUntilStart);
                    const timeout = futureStart.getTime() - now.getTime();

                    const timer = setTimeout(() => {
                        setTimerConfig(null); // clear countdown
                        setCountdown(0);
                    }, timeout);

                    return () => clearTimeout(timer);
                }
            } else {
                // timer was already started
                setCountdown(0);
            }
        }

    }, [timerStartedAt, setTimerConfig, timerConfig])

    if (countdown === 0 || timerConfig === null) {
        return null;
    }

    const {title, subtitle} = timerConfig;


    const formatCountdown = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedSeconds = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;
        return `${minutes}:${formattedSeconds}`;
    }

    const getTitle = (text) => {
        if (!text) {
            return null;
        }
        return <Typography align="center" variant="h3" sx={{color: '#ffffff'}}>{text}</Typography>;
    };


    const getSubtitle = (text) => {
        if (!text) {
            return null;
        }
        return <Typography align="center" variant="body6" sx={{color: 'rgba(255, 255, 255, 0.58)'}}>{text}</Typography>
    };

    return (
        <Dialog
            fullScreen
            open={true}
            onClose={handleClose}
            TransitionComponent={Transition}
            sx={{backgroundColor: "#111827"}}
        >
            <Stack direction="column" justifyContent="space-evenly" alignItems="center" display="flex"
                   sx={{height: '100%'}}>
                <Stack direction="column" display="flex" justifyContent="center" alignContent="center" spacing={1}>
                    {getSubtitle(tPuzzle('translate.game.puzzle.timer.watch_video'))}
                    {getTitle(tPuzzle(title))}
                    {getSubtitle(tPuzzle(subtitle))}
                </Stack>
                <Box sx={{
                    backgroundColor: "#1a2134",
                    width: "90%",
                    height: "50%",
                    boxShadow: '10px 10px 20px 0px rgba(16, 24, 41, 1)',
                    borderRadius: '20px 0px 0px 0px',
                    border: "2px solid rgba(255, 255, 255, 0.06)",
                    position: "relative",
                }}>
                    <Stack
                        sx={{
                            transform: 'translate(-50%, -50%)',
                            position: "absolute",
                            top: '50%',
                            left: '50%',
                        }}
                    >
                        <CircularProgress
                            size="15rem"
                            sx={{
                                color: "rgba(255, 87, 87, 1)",
                                [`& .${circularProgressClasses.circle}`]: {
                                    strokeLinecap: 'round',
                                }
                            }}
                        />
                    </Stack>
                    <Stack
                        direction="row"
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}
                    >
                        <Typography
                            align="center"
                            variant="h2"
                            sx={{color: '#ffffff'}}
                            fontWeight={700}
                            fontSize={64}
                        >
                            {formatCountdown(countdown)}
                        </Typography>
                    </Stack>
                </Box>
            </Stack>
        </Dialog>
    );
}
