import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import detective from '../detective.jpg';
import {Stack} from "@mui/material";
import cc_icon from "../cc_icon.png";
import {useTranslation} from "react-i18next";

function Chatbar({ data, playerNames }) {
    const { t } = useTranslation();

    const getAvatar = () => {
        if (data?.puzzle?.isTestPuzzle) {
            return cc_icon;
        }
        return detective;
    }
    const getName = () => {
        if (data?.puzzle?.isTestPuzzle) {
            return 'CouchClues';
        }
        return 'Steve';
    }


    return (
        <AppBar position="static" color="primary" elevation={0} sx={{ backgroundColor: '#F6F6F6'}}>
            <Container>
                <Stack direction="row" display="flex" py={1}>
                    <Avatar
                        alt="Detective avatar"
                        src={getAvatar()}
                        sx={{ backgroundColor: '#868686', img: { objectFit: 'contain' }}}
                    />
                    <Stack>
                        <Typography noWrap sx={{marginLeft: 2, color: 'black', fontSize: 18}}>{getName()}</Typography>
                        <Typography noWrap sx={{marginLeft: 2, color: '#898989', fontSize: 12 }}>
                            {t('chatWith')}{` ${playerNames?.myName}`}
                        </Typography>
                    </Stack>
                </Stack>
            </Container>
        </AppBar>
    );
}
export default Chatbar;
