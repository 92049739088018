
const isGuestName = "isGuest";

export const setGuestStore = (isGuest) => {
    if (isGuest !== null && isGuest !== undefined) {
        if (window.sessionStorage.getItem(isGuestName) !== null) {
            // some value exists
            if (isGuest.toString() !== window.sessionStorage.getItem(isGuestName)) {
                // value is different
                // console.error("isGuest in session storage cannot be overridden! ", isGuest)
                return;
            }
        } else {
            // new value
            window.sessionStorage.setItem(isGuestName, isGuest?.toString());
        }
    } else {
        console.error("isGuest was null or undefined! ", isGuest)
    }
}

export const isGuestSessionExists = () => {
    return window.sessionStorage.getItem(isGuestName) !== null;
}

export const isGuestFromStore = () => {
    if (window.sessionStorage.getItem(isGuestName) == null) {
        return null;
    }
    if (window.sessionStorage.getItem(isGuestName) && window.sessionStorage.getItem(isGuestName) === 'true') {
        return true;
    }
    return false;
}
