import cc_icon from "../cc_icon.png";
import detective from "../detective.jpg";

const getPossibleTypes = () => {
    const possibleTypes = new Set();
    possibleTypes.add("html");
    possibleTypes.add("text");
    possibleTypes.add("image");
    possibleTypes.add("image");
    possibleTypes.add("custom");
    return possibleTypes;
}

export const resolveMsgType = (m) => {
    let type = 'custom';
    if (getPossibleTypes().has(m?.action?.mediaType)) {
        type = m?.action?.mediaType;
    }
    return type;
}

export const getTitle = (title, transPuzzle, playerNames) => {
    if (title?.startsWith('translate')) {
        const partnerName = playerNames?.opponentName;
        // Check if the string contains multiple occurrences of 'translate'
        const count = (title.match(/translate/g) || []).length;
        if (count > 1) {
            const parts = title.split('translate');
            // multiple messages in one text sent from server
            let fullMsg = '';
            parts.forEach(part => {
                if (part.trim()) {
                    fullMsg = `${fullMsg} ${transPuzzle(`translate${part.trim()}`, { partnerName })}`;
                }
            });
            return fullMsg;
        }
        return transPuzzle(title, { partnerName, playerName: playerNames.myName });
    }
    return title;
}

export const getMsgDirection = (sender) => {
    if (sender === 'game') {
        return "incoming";
    }
    return "outgoing";
}

export const getAvatar = (puzzle) => {
    if (puzzle?.isTestPuzzle) {
        return cc_icon;
    }
    return detective;
}

export const getMessages = (isGuest, puzzle) => {
    if (isGuest) {
        return puzzle?.guestMessages;
    }
    return puzzle?.messages;
}
