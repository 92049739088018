import {styled} from "@mui/material";
import Box from "@mui/material/Box";

const PaperSegmentSx = styled(Box)`
  padding: 12px 8px;
  background: #F9F9F9;
  border-radius: 6px;
`;

const PaperSegment = ({ children, ...otherProps }) => {
    return (
        <PaperSegmentSx {...otherProps}>
            {children}
        </PaperSegmentSx>
    )
}

export default  PaperSegment;