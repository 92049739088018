import * as React from 'react';
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Stack
} from "@mui/material";
import {useTranslation} from "react-i18next";


export default function DifficultyPromptModal({ newDifficulty, onClose, onConfirm }) {
    const { t } = useTranslation('difficultyModal');
    const { t:tPuzzle } = useTranslation('puzzle');

    return (
        <Dialog
            open={newDifficulty !== null}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title" sx={{ color: 'white' }}>
                {t('areYouSure')} {tPuzzle(`translate.game.${newDifficulty}`)}
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ color: 'white' }}>
                    {t('dialogContent')}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{display: 'flex', justifyContent: 'flex-end'}}>
                <Stack direction="row" gap={3}>
                    <Button size="large" variant="contained" color="error"  onClick={onClose}>{t('cancel')}</Button>
                    <Button size="large" variant="contained" color="success"  onClick={onConfirm}>{t('confirm')}</Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}
